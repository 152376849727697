.rank-wrap {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.rank-wrap .rank-type {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: white;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #CCC;
  z-index: 999;
}

.rank-wrap .rank-type .rank-tab, .rank-wrap .rank-type .rank-tab-sel {
  width: 84px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
}

.rank-wrap .rank-type .rank-tab-sel {
  color: #DF2211;
  border-bottom: 3px solid #DF2211;;
}

.rank-wrap .rank-item {
  display: flex;
  height: 72px;
  width: 100%;
  padding: 0 32px;
  align-items: center;
  background: white;
}

.rank-wrap .rank-item .rank {
  font-size: 22px;
  font-weight: 500;
  color: #333;
}

.rank-wrap .rank-item .rank-avatar {
  width: 54px;
  height: 54px;
  margin: 0 12px;
  border-radius: 30px;
  background: #017EFF;
}

.rank-wrap .rank-item .rank-name {
  font-size: 18px;
  font-weight: 500;
  color: #444;
}

.rank-wrap .rank-item .rank-coin {
  font-size: 18px;
  color: #333;
}