.my-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
}

.my-wrap .my-coin {
  flex: 1;
  line-height: 40vh;
  text-align: center;
  font-size: 60px;
  background: #FD6E00;
  z-index: -1;
}

.my-wrap .my-coin .flag {
  font-size: 32px;
  margin-left: 4px;
}

.my-ops {
  margin: -40px 20px 0 20px;
  height: 72px;
  background: white;
  box-shadow:0 10px 16px 0px rgba(0,0,0,0.2);
  display: flex;
  border-radius: 8px;
  z-index: 9;
}

.my-ops .my-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72px;
  padding-top: 10px;
  color: #333;
}

.my-ops .my-icon {
  display: inline-block;
  color: #333;
  font-size: 32px;
}

.my-wrap2 {
  padding: 30px 20px 0 20px;
}

.my-wrap2 .history-item {
  width: 100%;
  min-height: 64px;
  align-items: center;
  display: flex;
  padding: 10px 0;
}

.my-wrap2 .history-item .content {
  flex: 1;
}

.my-wrap2 .history-item .content .title {
  font-size:16px;
  font-family:PingFangSC-Regular,PingFangSC;
  font-weight:400;
  color:#333;
  line-height:20rpx;
}

.my-wrap2 .history-item .content .time {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFangSC;
  font-weight:400;
  columns: #666;
  line-height:18px;
}

.my-wrap2 .history-item .change {
  font-size:32px;
  font-family:PingFangSC-Medium,PingFangSC;
  font-weight:500;
  color:#DF2211;
  line-height:42px;
}

.my-wrap2 .history-item .sub {
  font-weight:400;
  color:#333;
}

