.bonus-wrap {
  width: 100%;
  height: 100%;
  background: #EEE;
}

.bonus-wrap .select-user {
  background: white;
  display: flex;
  margin: 0 28px;
  padding: 0 12px;
  border-radius: 8px;
  height: 64px;
  align-items: center;
  font-size: 18px;
  color: #333;
}

.bonus-wrap .coin {
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding-left: 12px;
  font-size: 18px;
  height: 64px;
  color: #333;
  margin: 16px 28px 0 28px;
}

.bonus-wrap .sub-title {
  flex-shrink: 0;
}

.bonus-wrap .coin .coin-input {
  flex: 1;
  border: none;
  outline: none;
  background-color:transparent;
  padding-right: 40px;
}

.bonus-wrap .coin .number-desc {
  position: absolute;
  display: inline-block;
  flex-shrink: 0;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.bonus-wrap .reason {
  display: flex;
  background: white;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 18px;
  height: 156px;
  color: #333;
  margin: 16px 28px 0 28px;
}

.bonus-wrap .reason .sub-title {
  margin: 20px 0;
}

.bonus-wrap .reason .reason-input {
  padding: 10px 0 10px 10px;
  flex: 1;
  border: none;
  outline: none;
  background-color:transparent;
}

.bonus-wrap .coin-value {
  margin: 30px 32px 0 32px;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  color: #333; 
}

.bonus-wrap .btn-submit {
  margin: 20px 100px 0 100px;
  border-radius: 8px;
  background: #FD6E00;
  color: white;
  font-size: 20px;
  text-align: center;
  height: 48px;
  line-height: 48px;
}

.bonus-wrap .own-coin, .bonus-wrap .self-coin {
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
 
.coin-value-desp {
  font-size: 18px;
  margin-left: 3px;
}

.self-coin-desp {
  margin-left: 3px;
}
