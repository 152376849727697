@import '~antd/dist/antd.css';

.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.App {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
