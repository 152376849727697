.users-wrap {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.users-wrap .search {
  font-size: 14px;
  padding: 20px 22px;
  width: 100vw;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.5px #E0E0E0 solid;
}

.users-wrap .users-item {
  display: flex;
  height: 72px;
  box-sizing: border-box;
  width: 100vw;
  padding: 0 28px;
  align-items: center;
  background: white;
}

.users-wrap .users-item .users-avatar {
  width: 54px;
  height: 54px;
  margin-right: 12px;
  border-radius: 30px;
  background: #017EFF;
  flex-shrink: 0;
}

.users-wrap .users-item .users-name {
  font-size: 18px;
  font-weight: 500;
  color: #444;
  flex-shrink: 0;
}

.users-wrap .users-item .users-btn {
  margin-left: 8px;
  padding: 10px 0;
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
}

.users-wrap .users-item .owncoin-input {
  width: 80px;
  text-align: right;
  border: none;
  outline: none;
  background-color:transparent;
}

