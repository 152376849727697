.withdraw-wrap {
  width: 100%;
}

.withdraw-wrap .withdraw-item {
  display: flex;
  height: 72px;
  box-sizing: border-box;
  width: 100vw;
  padding: 0 20px;
  align-items: center;
  background: white;
}

.withdraw-wrap .withdraw-item .withdraw-avatar {
  width: 54px;
  height: 54px;
  margin-right: 12px;
  border-radius: 30px;
  background: #017EFF;
  flex-shrink: 0;
}

.withdraw-wrap .withdraw-item .withdraw-name {
  font-size: 18px;
  font-weight: 500;
  color: #444;
  flex-shrink: 0;
}

.withdraw-wrap .withdraw-item .withdraw-time {
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #666;
} 

.withdraw-wrap .withdraw-item .withdraw-coin {
  color: #333;
  font-size: 18px;
  margin-right: 10px;
}

.withdraw-wrap .withdraw-item .withdraw-confirm {
  background: #017EFF;
  border-radius: 8px;
  height: 48px;
  line-height: 48px;
  color: white;
  width: 80px;
  text-align: center;
  font-size: 16px;
}

.withdraw-wrap .withdraw-item .withdraw-confirmed {
  text-align: right;
  width: 80px;
  color: #666;
  font-size: 16px;
}

.withdraw-wrap .withdraw-item .withdraw-confirmed .time {
  font-size: 12px;
  margin-top: 4px;
  color: #999;
}