.picker-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  z-index: 1000;
  background: white;
}

.picker-wrap .search {
  font-size: 14px;
  padding: 20px 22px;
  width: 100vw;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.5px #E0E0E0 solid;
}

.picker-wrap .picker-item {
  display: flex;
  height: 72px;
  width: 100%;
  padding: 0 32px;
  align-items: center;
  background: white;
}

.picker-wrap .picker-item .picker-avatar {
  width: 54px;
  height: 54px;
  margin: 0 12px;
  border-radius: 30px;
  background: #017EFF;
}

.picker-wrap .picker-item .picker-name {
  font-size: 18px;
  font-weight: 500;
  color: #444;
}

.picker-wrap .picker-item .picker-role {
  font-size: 16px;
  color: #666;
}

