
.tab-wrap {
  background: white;
  border-top: 1px solid #DDD;
  display: flex;
  height: 64px;
  padding-top: 10px;
  width: 100%;
  flex-shrink: 0;
}

.tab-wrap .tab-select, .tab-wrap .tab-unselect {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.tab-wrap .tab-select {
  color: #FD6E00;
}

.tab-wrap .tab-icon, .tab-wrap .tab-icon-s {
  font-size: 28px;
}

.tab-wrap .tab-icon-s {
  color: #FD6E00;
}